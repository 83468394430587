import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { IDestacadoFotoProps } from '../destacadoFoto';

function destacadoFotoAdapter(data: any): IDestacadoFotoProps {
    return {
        title: data.tituloGeneral,
        desc: documentToReactComponents(data.descripcion.json),
        img: data.imagenPrincipal.file.url,
        btnLabel: data.tituloBoton,
        btnRedirect: data.redireccionBoton,
    };
}

export default destacadoFotoAdapter;
