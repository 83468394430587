import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    Card,
    Grow,
    Modal,
    Slide,
    Typography,
} from '@material-ui/core';
import YoutubePlayer from '../../molecules/youtube-player/youtubePlayer';
import useScreenSize from '../../../hooks/useScreenSize';
import useBrowserMode from '../../../shared/hooks/useBrowserMode';
import './cardsVideo.scss';

export interface ICardsVideoProps {
    title: string;
    description: any;
    cardsList: [
        {
            title: string;
            description: any;
            loop: string;
            youtubeId: string;
            bgColor: string;
            textColor: string;
        },
    ];
}

const CardsVideo = ({ title, description, cardsList }: ICardsVideoProps) => {
    const { document } = useBrowserMode();
    const [isHovered, setIsHovered] = useState(false);
    const [selected, setSelected] = useState(-1);
    const [selectedVideo, setSelectedVideo] = useState('none');
    const [openVideo, setOpenVideo] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const screenSize = useScreenSize();

    function handleMouseEnter(index) {
        setIsHovered(true);
        setSelected(index);
    }
    function handleMouseLeave() {
        setIsHovered(false);
        setSelected(-1);
    }
    function handleOpen(youtubeId, index) {
        if (youtubeId === 'none') return;
        if (youtubeId === '') {
            setSelectedVideo('none');
            return;
        }
        setSelectedVideo(youtubeId);
        setOpenVideo(true);
        setSelected(index);
    }

    function handleClose() {
        setOpenVideo(false);
        setSelected(-1);
    }

    useEffect(() => {
        const videos = document.getElementsByClassName('video-loop');
        for (let i = 0; i < videos.length; i++) {
            videos[i].play();
        }
    }, []);

    const elemRef = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setIsVisible(entries[0].isIntersecting);
            }
        });
        if (elemRef.current) {
            observer.observe(elemRef.current);
        }
        return () => observer.disconnect();
    }, []);

    return (
        <Box className="o-cards_video" component="section">
            <Box className="o-cards_video__intro">
                <Grow in={isVisible} timeout={1000}>
                    <div ref={elemRef}>
                        {title && (
                            <Typography
                                className="o-cards_video__h2"
                                variant="h2"
                            >
                                {title}
                            </Typography>
                        )}
                        {description && (
                            <Typography
                                className="o-cards_video__body"
                                variant="body1"
                            >
                                {description}
                            </Typography>
                        )}
                    </div>
                </Grow>
            </Box>
            {cardsList.map((card, key) => (
                <Card
                    className="o-cards_video__card"
                    style={{
                        color: card.textColor,
                    }}
                    key={key}
                >
                    <div
                        className={`o-cards_video__bg ${
                            ((isHovered && selected === key) ||
                                (selectedVideo === card.youtubeId &&
                                    openVideo)) &&
                            screenSize > 968
                                ? 'o-cards_video__bg__open'
                                : ''
                        }`}
                        style={{
                            backgroundColor: card.bgColor,
                        }}
                    ></div>
                    <Typography variant="h2" className="o-cards_video__title">
                        {card.title}
                    </Typography>
                    <Typography variant="body1" className="o-cards_video__desc">
                        {card.description}
                    </Typography>
                    <Button
                        onClick={() => handleOpen(card.youtubeId, key)}
                        style={{ padding: '0 !important' }}
                    >
                        <Box
                            className="o-cards_video__loop"
                            onMouseEnter={() => handleMouseEnter(key)}
                            onMouseLeave={() => handleMouseLeave()}
                        >
                            <video
                                width="100%"
                                height="102%"
                                controls={false}
                                autoPlay
                                muted
                                loop
                                className="video-loop"
                            >
                                <source src={card.loop} type="video/webm" />
                            </video>
                        </Box>
                    </Button>
                    <Modal
                        open={openVideo}
                        onClose={handleClose}
                        className="o-cards_video__modal"
                    >
                        <Slide
                            direction="up"
                            in={openVideo}
                            mountOnEnter
                            unmountOnExit
                            timeout={500}
                        >
                            <Box className="o-cards_video__video">
                                <YoutubePlayer youtubeId={selectedVideo} />
                            </Box>
                        </Slide>
                    </Modal>
                </Card>
            ))}
        </Box>
    );
};

export default CardsVideo;
