import React, { useEffect, useRef, useState } from 'react';
import { Box, Container, Fade, Grid, Typography } from '@material-ui/core';
import Slider from 'react-slick';
import ArrowButton from '../../atoms/arrow-button/arrowButton';
import Switcher from '../../atoms/switcher/switcher';
import { normalizeTwoWords } from '../../../shared/utils/stringUtilities';
import { findTarget } from '../../../utils/findTarget';
import { generateSlug } from '../../../utils/generateSlug';
import styles from './portfolio.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './portfolio.scss';

export interface IPortfolioProps {
    title: string;
    description: any;
    cardsList: [
        {
            icon: string;
            title: string;
            redirect: string;
            category: string;
            gtmTag: string;
        },
    ];
}

const Portfolio = ({ title, description, cardsList }: IPortfolioProps) => {
    const [checked, setChecked] = useState(false);
    const [people, setPeople]: any = useState([]);
    const [companies, setCompanies]: any = useState([]);

    useEffect(() => {
        cardsList.forEach((card) => {
            if (card.category.toLowerCase().includes('person')) {
                setPeople((people) => [...people, card]);
            } else {
                setCompanies((companies) => [...companies, card]);
            }
        });
    }, []);

    const [isVisible, setIsVisible] = useState(false);
    const PortfolioRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setIsVisible(entries[0].isIntersecting);
            }
        });
        if (PortfolioRef.current) {
            observer.observe(PortfolioRef.current);
        }
        return () => observer.disconnect();
    }, []);

    const settings = {
        dots: true,
        infinite: false,
        autoplay: false,
        rows: 2,
        slidesPerRow: 4,
        nextArrow: <ArrowButton />,
        prevArrow: <ArrowButton />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesPerRow: 3,
                },
            },
            {
                breakpoint: 1025,
                settings: {
                    rows: 1,
                },
            },
            {
                breakpoint: 960,
                settings: {
                    rows: 1,
                    slidesPerRow: 4,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    rows: 1,
                    slidesPerRow: 2,
                },
            },
        ],
    };

    return (
        <Fade in={isVisible} timeout={1000}>
            <Container
                ref={PortfolioRef}
                component="section"
                id={normalizeTwoWords(title)}
            >
                <Grid container className={`${styles.o_portfolio} o_portfolio`}>
                    <Grid
                        item
                        md={4}
                        xs={12}
                        className={styles.o_portfolio__info}
                    >
                        <Typography
                            variant="h2"
                            className={styles.o_portfolio__h2}
                        >
                            {title}
                        </Typography>
                        <p className={styles.o_portfolio__desc}>
                            {description}
                        </p>
                    </Grid>
                    <Grid
                        item
                        md={8}
                        sm={12}
                        xs={12}
                        className={styles.o_portfolio__grid}
                    >
                        <Box className={styles.o_portfolio__switcher}>
                            <Switcher
                                checked={checked}
                                handleClick={setChecked}
                            />
                        </Box>
                        <Slider {...settings} accessibility>
                            {(checked ? companies : people).map((card, key) => (
                                <div
                                    key={key}
                                    className={styles.o_portfolio__card}
                                >
                                    <a
                                        href={card.redirect}
                                        className={`gtm_click_carrusel_comfama_home_body_servicios_${
                                            checked ? 'empresas' : 'personas'
                                        }_${generateSlug(card.gtmTag)} ${
                                            styles.o_portfolio__container
                                        }`}
                                        target={findTarget(card.redirect)}
                                        rel={
                                            findTarget(card.redirect) ===
                                            '_blank'
                                                ? 'noreferrer follow'
                                                : 'referrer follow'
                                        }
                                    >
                                        <i
                                            className={`gtm_click_carrusel_comfama_home_body_servicios_${
                                                checked
                                                    ? 'empresas'
                                                    : 'personas'
                                            }_${generateSlug(
                                                card.gtmTag,
                                            )} icon-${card.icon}`}
                                        />
                                        <Typography
                                            variant="h3"
                                            className={`gtm_click_carrusel_comfama_home_body_servicios_${
                                                checked
                                                    ? 'empresas'
                                                    : 'personas'
                                            }_${generateSlug(card.gtmTag)} ${
                                                styles.o_portfolio__h3
                                            }`}
                                        >
                                            {card.title}
                                        </Typography>
                                    </a>
                                </div>
                            ))}
                        </Slider>
                    </Grid>
                </Grid>
            </Container>
        </Fade>
    );
};

export default Portfolio;
