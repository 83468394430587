import React, { useEffect, useRef, useState } from 'react';
import { Box, Fade, Grid } from '@material-ui/core';
import Slider from 'react-slick';
import Button2 from '../../atoms/button2/button2';
import ArrowButton from '../../atoms/arrow-button/arrowButton';
import styles from './hero.module.scss';
import './hero.scss';

export interface IHeroProps {
    slideList: [
        {
            image: string;
            bg?: string;
            style2?: boolean;
            title: string;
            gtmTag: string;
            description: any;
            subtitle: string;
            btnLabel: string;
            redirect?: string;
        },
    ];
}

const Hero = ({ slideList }: IHeroProps) => {
    const [isVisible, setIsVisible] = useState(false);
    const HeroRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setIsVisible(entries[0].isIntersecting);
            }
        });
        if (HeroRef.current) {
            observer.observe(HeroRef.current);
        }
        return () => observer.disconnect();
    }, []);
    const settings = {
        dots: true,
        slidesToShow: 1,
        infinite: false,
        slidesToScroll: 1,
        nextArrow: <ArrowButton />,
        prevArrow: <ArrowButton />,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    arrows: false,
                    autoplay: true,
                    infinite: true,
                },
            },
        ],
    };
    return (
        <Fade in={isVisible} timeout={1000}>
            <Box className={`${styles.o_hero} o_hero`}>
                <section ref={HeroRef}>
                    <Slider {...settings} className={styles.o_hero__slider}>
                        {slideList.map((slide, index) => (
                            <Box
                                key={index}
                                className={styles.o_hero__slide}
                            >
                                {slide.bg && (
                                    <img
                                        src={slide.bg}
                                        alt=""
                                        className={styles.o_hero__bg}
                                    />
                                )}
                                {!slide.style2 && (
                                    <div className={styles.o_hero__bg2}>
                                        <img
                                            src={slide.image}
                                            alt=""
                                            className={styles.o_hero__img}
                                        />
                                    </div>
                                )}
                                <Grid
                                    container
                                    lg={12}
                                    md={10}
                                    xs={12}
                                    className={styles.o_hero__container}
                                >
                                    <Grid
                                        item
                                        md={5}
                                        xs={12}
                                        className={styles.o_hero__info}
                                    >
                                        <span
                                            className={styles.o_hero__subtitle}
                                        >
                                            {slide.subtitle}
                                        </span>
                                        <div
                                            className={`${styles.o_hero__title} o_hero__title`}
                                            dangerouslySetInnerHTML={{
                                                __html: slide.title,
                                            }}
                                        />
                                        <p
                                            className={`${styles.o_hero__desc} o_hero__desc`}
                                        >
                                            {slide.description}
                                        </p>
                                        {slide.redirect && (
                                            <Button2
                                                label={slide.btnLabel}
                                                redirect={slide.redirect}
                                                classname={styles.o_hero__btn}
                                                id={`gtm_click_banner_comfama_home_body_${slide.gtmTag}`}
                                            />
                                        )}
                                    </Grid>
                                    {slide.style2 && (
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            className={styles.o_hero__img2}
                                        >
                                            <img
                                                src={slide.image}
                                                alt=""
                                                className={styles.o_hero__media}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                        ))}
                    </Slider>
                </section>
            </Box>
        </Fade>
    );
};

export default Hero;
