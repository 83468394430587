import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { IPortfolioProps } from '../portfolio';

function portfolioAdapter(data: any): IPortfolioProps {
    return {
        title: data.tituloGeneral,
        description: documentToReactComponents(data.descripcion.json),
        cardsList:
            data?.referenciaPlantillaContenido?.map((card) => ({
                icon: card.youtubeId ? card.youtubeId : 'user1',
                title: card.tituloBanner,
                redirect: card.redireccionBoton,
                category: card.tituloBoton,
                gtmTag: card.youtubeId
            })) ?? [],
    };
}

export default portfolioAdapter;
