import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { IBannerArbProps } from '../bannerArboles';

function bannerArbolesAdapter(data: any): IBannerArbProps {
    return {
        title: data.tituloGeneral,
        cards:
            data?.referenciaPlantillaContenido?.map((card) => ({
                text: documentToReactComponents(card.descripcion.json),
                bgImg: card.imagenPrincipal[0].file.url,
                imgList: card.imagenPrincipal.slice(1),
                cardTitle: card.tituloBanner,
                descTitle: card.tituloDescripcion,
                redirection: card.redireccionBoton,
                btnTitle: card.tituloBoton,
                bgColor: card.tipoTitulo ? card.tipoTitulo : '#00741B'
            })) ?? [],
    };
}

export default bannerArbolesAdapter;
