import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { IHeroProps } from '../hero';
import { customTitle } from '../../../../utils/customTitle';
import { generateSlug } from '../../../../utils/generateSlug';

function heroAdapter(data: any): IHeroProps {
    return {
        slideList:
            data?.referenciaPlantillaContenido?.map((slide) => ({
                image: slide.imagenPrincipal[0].file.url,
                bg:
                    slide.imagenPrincipal[1] &&
                    slide.imagenPrincipal[1].file.url,
                style2:
                    slide.tipoTitulo || slide.imagenPrincipal.lenght < 2
                        ? true
                        : false,
                title: customTitle(slide.tituloBanner),
                gtmTag: generateSlug(slide.tituloBanner),
                description: documentToReactComponents(slide.descripcion.json),
                subtitle: slide.tituloDescripcion,
                btnLabel: slide.tituloBoton,
                redirect: slide.redireccionBoton && slide.redireccionBoton,
            })) ?? [],
    };
}

export default heroAdapter;
