import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ICarrusel3Props } from '../carrusel3';

function carrusel3Adapter(data: any): ICarrusel3Props {
    return {
        title: data.tituloGeneral ? data.tituloGeneral : '',
        description: data.descripcion
            ? documentToReactComponents(data.descripcion.json)
            : '',
        cardsList:
            data?.referenciaPlantillaContenido?.map((card) => ({
                image: card.imagenPrincipal[0].file.url,
                title: card.tituloBanner,
                bg: card.tituloDescripcion ? card.tituloDescripcion : '#FAF798',
                color: card.tipoTitulo ? card.tipoTitulo : '#000',
                description: documentToReactComponents(card.descripcion.json),
                redirect: card.redireccionBoton ? card.redireccionBoton : '',
            })) ?? [],
    };
}

export default carrusel3Adapter;
