import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { IAcordeonTabsProps } from '../acordeonTabs';
import { customTitle } from '../../../../utils/customTitle';

function acordeonTabsAdapter(data: any): IAcordeonTabsProps {
    return {
        title: data.tituloGeneral ? data.tituloGeneral : '',
        desc: data.descripcion
            ? documentToReactComponents(data.descripcion.json)
            : '',
        cta: {
            title: data?.referenciaPlantillaContenido[0].tituloBanner,
            desc: data?.referenciaPlantillaContenido[0].descripcion
                ? documentToReactComponents(
                      data?.referenciaPlantillaContenido[0].descripcion.json,
                  )
                : '',
            redirect: data?.referenciaPlantillaContenido[0].redireccionBoton,
            btnLabel: data?.referenciaPlantillaContenido[0].tituloBoton,
        },
        cardsList:
            data?.referenciaPlantillaContenido?.slice(1).map((card) => ({
                title: customTitle(card.tituloBanner),
                desc: card.descripcion
                    ? card.descripcion.json
                    : '',
                cat: card.tituloBoton ? card.tituloBoton : 'Personas',
            })) ?? [],
    };
}

export default acordeonTabsAdapter;
