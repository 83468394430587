import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { IPrefooterProps } from '../prefooter';

function prefooterAdapter(data: any): IPrefooterProps {
    return {
        bg: data.listaImagenes[0].file.url,
        text: documentToReactComponents(data.descripcion.json),
        url: data.redireccionBoton,
        btnLabel: data.tituloBoton,
    };
}

export default prefooterAdapter;
