import React, { useEffect, useRef, useState } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido"
import useBrowserMode from '../../../shared/hooks/useBrowserMode'
import outsideClick from '../../../utils/outsideClick'
import './projects.scss'

const Projects = (props: ReferenciaContenido) => {

    const [regions, setRegions] = useState<string[]>([])
    const [icons, setIcons] = useState<string[]>([])
    const [selectedRegion, setSelectedRegion] = useState<string>('')
    const [openFilter, setOpenFilter] = useState<boolean>(false)
    const [filterLabel, setFilterLabel] = useState<string>('Explora las categorías')
    const [mobileView, setMobileView] = useState<boolean>(false)
    const { isBrowser, window } = useBrowserMode();
    
    const filterRegions = () => {
        let regionsAux: string[] = []
        let iconsAux: string[] = []
        props.referenciaPlantillaContenido.forEach(function (elem) {
            if ( !regionsAux.includes(elem.youtubeId) ) {
                regionsAux.push(elem.youtubeId)
                if (elem.imagenPrincipal[1]) {
                    iconsAux.push(elem.imagenPrincipal[1].file.url)
                }
            }
        })
        setRegions(regionsAux)
        setIcons(iconsAux)
    }

    useEffect(() => {
        isBrowser && window.screen.width < 960 && setMobileView(true)
        filterRegions()
        setFilterLabel(props.tituloBoton)
    }, [])

    const handleFilter = (region) => {
        setSelectedRegion(region)
        setFilterLabel(region)
    }

    const filterContainerRef = useRef(null);

    if (isBrowser) {
        window.addEventListener("resize", function() {
            isBrowser && window.screen.width < 960 ? setMobileView(true) : setMobileView(false)
        })
    }

    return (
        <div className='o-projects'>
            { props.descripcion &&
                <div className="o-projects__desc">
                    <p>{ documentToReactComponents(props.descripcion.json) }</p>
                </div>
            }
            <div className="o-projects__filter">
                { !mobileView ?
                    regions.map((region, key) => (
                        <button key={key} onClick={ () => handleFilter(region) } className={`o-projects__filter__btn ${region === selectedRegion ? 'o-projects__filter__btn--active' : ''}`}>
                            { icons.length > 0 &&
                                <img src={icons[key]} alt="" />
                            }
                            <span>{ region }</span>
                        </button>
                    ))
                    :
                    <div className="o-projects__filter__container" onClick={() => setOpenFilter(!openFilter)} ref={filterContainerRef}>
                        <span>{ filterLabel }</span><i className="icon-chevron-down1"></i>
                        { openFilter &&
                            <ul className="o-projects__filter__container__ul">
                                { regions.map((region, key) => (
                                    <li key={key} onClick={ () => handleFilter(region) } className={`o-projects__filter__container__ul__li ${region === selectedRegion ? 'o-projects__filter__container__ul__li--active' : ''}`}>
                                        { icons.length > 0 &&
                                            <img src={icons[key]} alt="" />
                                        }
                                        <span>{ region }</span>
                                    </li>
                                ))}
                            </ul>
                        }
                        {
                            outsideClick(filterContainerRef, () => setOpenFilter(false))
                        }
                    </div>
                }
            </div>
            <div className="o-projects__cards">
                {
                    props.referenciaPlantillaContenido.map((card, key) => (
                        (selectedRegion === card.youtubeId || selectedRegion === '') &&
                        <div key={key} className="o-projects__cards__card">
                            <div className="o-projects__cards__card__img">
                                <img src={card.imagenPrincipal[0].file.url} alt="" />
                            </div>
                            <div className="o-projects__cards__card__info">
                                { card.tituloDescripcion &&
                                    <span className="o-projects__cards__card__info__city">
                                        <i className='icon-map-marker-alt'></i>
                                        <span>{ card.tituloDescripcion }</span>
                                    </span>
                                }
                                <span className="o-projects__cards__card__info__title">{ card.tituloBanner }</span>
                                { card.tipoTitulo &&
                                    <span className="o-projects__cards__card__info__price">
                                        <i className='icon-dollar-sign'></i>
                                        <span>{ card.tipoTitulo }</span>
                                    </span>
                                }
                                { card.tituloBoton &&
                                    <span className="o-projects__cards__card__info__area">
                                        <i className='icon-home'></i>
                                        <span>{ card.tituloBoton }</span>
                                    </span>
                                }
                                { card.descripcion &&
                                    <p className="o-projects__cards__card__info__desc">{ documentToReactComponents(card.descripcion.json) }</p>
                                }
                            </div>
                            <a id={ card.idBoton } className='o-projects__cards__card__btn' href={card.redireccionBoton} rel='follow referrer'>
                                Ver más
                            </a>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Projects
