import React, { useState } from "react";
import Slider from "react-slick";
import DocumentCard from "../../atoms/document-card/documentCard";
import CardVideo from "../../atoms/card-video/cardVideo";
import GalleryCard from "../gallery-card/galleryCard";
import YoutubePlayer from "../youtube-player/youtubePlayer";
import AudioCard from "../../atoms/audio-card/audioCard";
import "./reviewSlider.scss";

interface DocumentsSliderInterface {
  reviews: any;
  gtmClass: string;
  type?: string;
  slides?: {
    show: number,
    rows: number,
    s1560: number
  };
}

const ReviewSlider = (props: DocumentsSliderInterface) => {

  const [sliderIndex, setSliderIndex] = useState(0);
  const [slider, setSlider] = useState<any>();

  // Funciones para desktop
  const goToNext = () => {
    slider.slickGoTo(sliderIndex + 2);
  };

  const goToBack = () => {
    slider.slickGoTo(sliderIndex - 2);
  };

  const isActiveNext = (_true: any, _false: any) => {
    return sliderIndex < props.reviews.length - 1 ? _true : _false;
  };

  const isActiveBack = (_true: any, _false: any) =>
    sliderIndex > 0 ? _true : _false;

  const settings = {
    className: "center",
    padding: "20%",
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: props.slides?.show || 2,
    slidesToScroll: props.type === "card-bh" ? 3 : 1,
    beforeChange: (_: number, next: number) => setSliderIndex(next),
    slidesPerRow: 1,
    rows: props.slides?.rows || 1,
    responsive: [
      {
        breakpoint: 1560,
        settings: {
          slidesToShow: props.slides?.s1560,
        },
      },
    ],
  };
  const settingsM = {
    className: "center",
    centerPadding: "10%",
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (_: number, next: number) => setSliderIndex(next),
    rows: props.type === "audio" ? 2 : 1,
    slidesPerRow: props.type === "audio" ? 2 : 1,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          centerMode: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          rows: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1.05,
          slidesToScroll: 1,
          rows: props.type === "audio" ? 2 : 1,
        },
      },
    ],
  };

  const handleBookMobile = () => {
    const booksAux = Array(0);
    const limit = props.reviews.length;
    for (let i = 0; i < limit; i++) {
      booksAux.push( 
        props.type === "gallery" ? 
        <GalleryCard gallery={props.reviews[i]} key={i} /> : (
          props.type === "downloadVideo" ? 
          (<div className="m-video-downloader__list__item">
            <div className="m-video-downloader__list__item__video">
              <YoutubePlayer
                  youtubeId={props.reviews[i].youtubeId}
                  titulo={props.reviews[i].tituloBanner || "Video Comfama"}
              />
            </div>
            <div className="m-video-downloader__list__item__title">
              <h3><b>{props.reviews[i].tituloBanner}</b></h3>
              <a href={props.reviews[i].redireccionBoton} target="_blank" rel="noreferrer">
                <i className="icon-download"></i>
              </a>
            </div>
          </div>)
          : (props.type === "document" ? <DocumentCard document={props.reviews[i]} key={i} /> 
          : (props.type === "audio" ? <AudioCard download audio={props.reviews[i]}/>
          : <CardVideo review={props.reviews[i]} key={i} gtmClass={props.gtmClass} />)))
      );
    }
    return booksAux;
  };

  return (
    <section className="m-review-slider">
      <div className={`hide-on-mobile ${props.type === 'audio' ? '--audio-card' : ""}`}>
        <Slider ref={(_slider) => setSlider(_slider)} {...settings}>
          {props.reviews.map((review, idx) => {
            if (props.type === "audio") {
              return (
                <div className="review-desktop" key={idx}>
                  <AudioCard download audio={review}/>
                </div>
              );
            } else if (!props.type) {
              return (
                <div className="review-desktop" key={idx}>
                  <CardVideo review={review} key={idx} gtmClass={props.gtmClass}/>
                </div>
              );
            }
          })}
        </Slider>
        <div
          className={`arrow-content arrow-content__left ${props.type === 'audio' ? '--hide' : ""}`}
          onClick={isActiveBack(goToBack, undefined)}
        >
          <div className="arrows">
            <div className="arrows-contain">
              <div
                className={isActiveBack(
                  "arrow icon-chevron-left active",
                  "arrow icon-chevron-left"
                )}
              />
            </div>
          </div>
        </div>
        <div
          className={`arrow-content arrow-content__right ${props.type === 'audio' ? '--hide' : ""}`}
          onClick={isActiveNext(goToNext, undefined)}
        >
          <div className="arrows">
            <div className="arrows-contain">
              <div
                className={isActiveNext(
                  "arrow icon-chevron-right active",
                  "arrow icon-chevron-right"
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="show-on-mobile">
        <Slider {...settingsM}>
          {handleBookMobile().map((books) => books)}
        </Slider>
      </div>
    </section>
  );
};

export default ReviewSlider;
