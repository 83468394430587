import React, { useEffect, useRef, useState } from 'react';
import { Container, Fade, Grid, Typography } from '@material-ui/core';
import Button2 from '../../atoms/button2/button2';
import styles from './destacadoVideo.module.scss';
import { generateSlug } from '../../../utils/generateSlug';

export interface IDestacadoVideoProps {
    title: string;
    desc: any;
    img?: string;
    video?: string;
    btnLabel: string;
    btnRedirect: string;
}

const DestacadoVideo = ({
    title,
    desc,
    img,
    video,
    btnLabel,
    btnRedirect,
}: IDestacadoVideoProps) => {
    const [isVisible, setIsVisible] = useState(false);
    const DestacVidRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setIsVisible(entries[0].isIntersecting);
            }
        });
        if (DestacVidRef.current) {
            observer.observe(DestacVidRef.current);
        }
        return () => observer.disconnect();
    }, []);

    return (
        <Fade in={isVisible} timeout={1000}>
            <Container
                ref={DestacVidRef}
                component="section"
                className={styles.o_destacado_video}
            >
                <Grid
                    item
                    lg={12}
                    md={10}
                    className={styles.o_destacado_video__container}
                >
                    {video ? (
                        <video
                            playsInline
                            controls={false}
                            autoPlay
                            muted
                            loop
                            className={styles.o_destacado_video__img}
                        >
                            <source src={video} type="video/webm" />
                            <source src={video} type="video/mp4" />
                            Tu navegador no soporta video HTML5.
                        </video>
                    ) : img ? (
                        <img
                            className={styles.o_destacado_video__img}
                            src={img}
                            alt=""
                        />
                    ) : (
                        <></>
                    )}
                    <Typography
                        className={styles.o_destacado_video__h2}
                        variant="h2"
                    >
                        {title}
                    </Typography>
                    <Typography
                        className={styles.o_destacado_video__desc}
                        variant="body1"
                    >
                        {desc}
                    </Typography>
                    <Button2
                        label={btnLabel}
                        redirect={btnRedirect}
                        classname={`gtm_click_button_comfama_home_body_autopauta_${generateSlug(
                            title,
                        )} ${styles.o_destacado_video__btn}`}
                    />
                </Grid>
            </Container>
        </Fade>
    );
};

export default DestacadoVideo;
