import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { IDestacadoVideoProps } from '../destacadoVideo';

function destacadoVideoAdapter(data: any): IDestacadoVideoProps {
    return {
        title: data.tituloGeneral,
        desc: documentToReactComponents(data.descripcion.json),
        img: data.imagenPrincipal ? data.imagenPrincipal.file.url : '',
        video: data.listaImagenes ? data.listaImagenes[0].file.url : '',
        btnLabel: data.tituloBoton,
        btnRedirect: data.redireccionBoton,
    };
}

export default destacadoVideoAdapter;
