import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ICarruselCategoriasProps } from '../carruselCategorias';

function carruselCategoriasAdapter(data: any): ICarruselCategoriasProps {
    return {
        title: data.tituloGeneral ? data.tituloGeneral : '',
        description: data.descripcion ? documentToReactComponents(data.descripcion.json) : '',
        btnTitle: data.tituloBoton,
        btnRedirect: data.redireccionBoton ? data.redireccionBoton : '',
        cardsList:
            data?.referenciaPlantillaContenido?.map((card) => ({
                image: card.imagenPrincipal[0].file.url,
                title: card.tituloBanner,
                desc: documentToReactComponents(card.descripcion.json),
                btnRedirect: card.redireccionBoton,
                btnTitle: card.tituloBoton,
                cat: card.tituloDescripcion,
                bgColor: card.tipoTitulo ? card.tipoTitulo.split(';')[0] : '#FAF798',
                color: card.tipoTitulo && card.tipoTitulo.split(';')[1] ? card.tipoTitulo.split(';')[1] : '#000',
            })) ?? [],
    };
}

export default carruselCategoriasAdapter;
