import React, { useState, useEffect, useMemo } from 'react';
import { ReferenciaContenido } from '../../../interfaces/ReferenciaContenido';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { ReferenciaPlantillaContenido } from '../../../interfaces/ReferenciaPlantillaContenido';
import useBrowserMode from '../../../shared/hooks/useBrowserMode';
import Button from '../../atoms/button/button';
import { normalizeTwoWords, findTarget, findRel, OptimizeImage } from "../../../shared/utils/stringUtilities";
import Img from "gatsby-image";
import './galleryExtended.scss';

const GalleryExtended = (props:ReferenciaContenido) => {
   
   const { isBrowser, window } = useBrowserMode();
   const [content, setContent] = useState<ReferenciaPlantillaContenido[]>([]);
   const [pager, setPager] = useState<number>(0);
   const [limit, setLimit] = useState<number>(6);
   const isMoreSix = props.referenciaPlantillaContenido.length > limit;
   
   useEffect( () => {
      change();
      isBrowser && window.addEventListener('resize', change);
      return () => window.removeEventListener('resize', change);
   }, [] )

   useMemo( () => reset(), [limit] )

   function change() {
      if(isBrowser) {
         setLimit( window.screen.width <= 960 ? 3 : 6);
      }
   }

   function reset() {
      setContent( isMoreSix ? props.referenciaPlantillaContenido.slice(0, limit) : props.referenciaPlantillaContenido );
      setPager(limit);
   }
   
   const getMore = () => {
      setContent(content.concat(props.referenciaPlantillaContenido.slice(pager, pager+limit)));
      setPager(pager+limit);
   }

   return (
      <div className='o-gallery-extended'>
         <h2 className='o-gallery-extended__title' >{props.tituloGeneral}</h2>
         <div className='o-gallery-extended__container' >
            {content.map( (value, index) => (
               <a key={index}
                  id={value.idBoton && value.idBoton}
                  className={`o-gallery-extended__container__item ${value.idBoton ? value.idBoton : ''}`}
                  rel={findRel(value.redireccionBoton)}
                  href={value.redireccionBoton} 
                  title={value.tituloBanner}
                  target={ findTarget(value.redireccionBoton) }
               >
                  { value.imagenPrincipal &&
                     value.imagenPrincipal[0].fluid ?
                     <Img  className={`o-gallery-extended__container__item__img ${value.idBoton ? value.idBoton : ''}`}
                           fluid= { value.imagenPrincipal[0].fluid }
                           alt={ value.imagenPrincipal[0].title || "imagen galeria extendida" }
                     />
                     :
                     <img  className={`o-gallery-extended__container__item__img ${value.idBoton ? value.idBoton : ''}`}
                           src={OptimizeImage(value.imagenPrincipal[0].file.url, value.imagenPrincipal[0].file.contentType, 768)}
                           alt={ value.imagenPrincipal[0].title || "imagen galeria extendida" }
                           loading="lazy"
                     />
                  }
                  <div className={`o-gallery-extended__container__item__box ${value.idBoton ? value.idBoton : ''}`} >
                     <span className={`o-gallery-extended__container__item__box__title ${value.idBoton ? value.idBoton : ''}`}>
                        {value.tituloBanner}
                     </span>
                     {value.descripcion &&
                        <h3 className={`o-gallery-extended__container__item__box__text ${value.idBoton ? value.idBoton : ''}`}>
                           {value.descripcion.json.content && documentToReactComponents(value.descripcion.json)}
                        </h3>
                     }
                  </div>
               </a>
            ))}
         </div>
         { props.referenciaPlantillaContenido.length > pager &&
            <button className='o-gallery-extended__button' 
               onClick={getMore}
            >Ver más</button>
         }
         { content.length >= props.referenciaPlantillaContenido.length && props.tituloBoton &&
            <Button 
               classname={'o-gallery-extended__button'}
               gtmMark={`gtm${normalizeTwoWords(props.nombreCategoria)}${normalizeTwoWords(props.tituloBoton)}`}
               type={'link'}
               target={'_self'}
               strictCss={true}
               isDisabled={false}
               text={props.tituloBoton}
               href={props.redireccionBoton}
            />
         }
      </div>
   )
}

export default GalleryExtended;
