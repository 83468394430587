import React from 'react';
import './prefooter.scss';

export interface IPrefooterProps {
    bg: string;
    text: any;
    url?: string;
    btnLabel: string;
}

const Prefooter = (props: IPrefooterProps) => {
    const { bg, text, url, btnLabel } = props;

    return (
        <div className="m-prefooter">
            <img loading="lazy" className="m-prefooter__bg" alt="" src={bg} />
            <p className="m-prefooter__text">{text}</p>
            {url && (
                <a
                    id="arboles--cta"
                    href={url}
                    rel="noreferrer follow"
                    target="_blank"
                    className="m-prefooter__btn"
                >
                    <span>{btnLabel}</span>
                    <i className="icon-chevron-right1"></i>
                </a>
            )}
        </div>
    );
};

export default Prefooter;
