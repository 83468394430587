import React, { useEffect, useRef, useState } from 'react';
import { Box, Container, Fade, Grid, Typography } from '@material-ui/core';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';
import Switcher from '../../atoms/switcher/switcher';
import styles from './acordeonTabs.module.scss';
import { generateSlug } from '../../../utils/generateSlug';

export interface IAcordeonTabsProps {
    title: string;
    desc: any;
    cta: {
        title: string;
        desc: any;
        redirect: string;
        btnLabel: string;
    };
    cardsList: [
        {
            title: string;
            desc: any;
            cat: string;
        },
    ];
}

const AcordeonTabs = ({ title, desc, cta, cardsList }: IAcordeonTabsProps) => {
    const [checked, setChecked] = useState(false);
    const [people, setPeople]: any = useState([]);
    const [companies, setCompanies]: any = useState([]);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(-1);
    const [isVisible, setIsVisible] = useState(false);
    const AccordTabsRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setIsVisible(entries[0].isIntersecting);
            }
        });
        if (AccordTabsRef.current) {
            observer.observe(AccordTabsRef.current);
        }
        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        cardsList.forEach((card) => {
            if (card.cat.toLowerCase().includes('empresa')) {
                setCompanies((companies) => [...companies, card]);
            } else {
                setPeople((people) => [...people, card]);
            }
        });
    }, []);

    const handleButtonClick = (index) => {
        setSelected(index);
        setOpen(!open);
    };

    const renderHyperlink = (node: any) => {
        return (
            <a href={node.data.uri} target="_blank" rel="noreferrer follow">
                {node.content[0].value}
            </a>
        );
    };

    const options = {
        renderNode: {
            [INLINES.HYPERLINK]: renderHyperlink,
        },
    };

    return (
        <Fade in={isVisible} timeout={1000}>
            <Container
                ref={AccordTabsRef}
                component="section"
                className={styles.o_acordeon_tabs}
            >
                <Grid
                    container
                    lg={12}
                    md={10}
                    className={styles.o_acordeon_tabs__container}
                >
                    <Grid
                        item
                        lg={6}
                        sm={12}
                        className={styles.o_acordeon_tabs__left}
                    >
                        <Box className={styles.o_acordeon_tabs__info}>
                            <Typography variant="h2">{title}</Typography>
                            <Typography variant="body1">{desc}</Typography>
                        </Box>
                        <Box className={styles.o_acordeon_tabs__switcher}>
                            <Switcher
                                checked={checked}
                                handleClick={setChecked}
                            />
                        </Box>
                        <Box className={styles.o_acordeon_tabs__questions}>
                            {(checked ? companies : people).map(
                                (card, index) => (
                                    <div
                                        key={index}
                                        className={`${
                                            styles.o_acordeon_tabs__question
                                        } ${
                                            open && index === selected
                                                ? styles.o_acordeon_tabs__open
                                                : ''
                                        }`}
                                    >
                                        <button
                                            onClick={() =>
                                                handleButtonClick(index)
                                            }
                                            className={`gtm_click_button_comfama_home_body_preguntas_${
                                                checked
                                                    ? 'empresas'
                                                    : 'personas'
                                            }_${generateSlug(card.title)}`}
                                        >
                                            <h3
                                                className={`gtm_click_button_comfama_home_body_preguntas_${
                                                    checked
                                                        ? 'empresas'
                                                        : 'personas'
                                                }_${generateSlug(card.title)}`}
                                            >
                                                {card.title}
                                            </h3>
                                            <i
                                                className={`gtm_click_button_comfama_home_body_preguntas_${
                                                    checked
                                                        ? 'empresas'
                                                        : 'personas'
                                                }_${generateSlug(
                                                    card.title,
                                                )} icon-plus1`}
                                            />
                                        </button>
                                        {open && index === selected && (
                                            <p>
                                                {documentToReactComponents(
                                                    card.desc,
                                                    options,
                                                )}
                                            </p>
                                        )}
                                    </div>
                                ),
                            )}
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xl={4}
                        lg={5}
                        sm={8}
                        className={styles.o_acordeon_tabs__right}
                    >
                        <Box className={styles.o_acordeon_tabs__cta}>
                            <Typography
                                variant="h2"
                                className={styles.o_acordeon_tabs__ctatitle}
                            >
                                {cta.title}
                            </Typography>
                            <Typography
                                variant="body1"
                                className={styles.o_acordeon_tabs__ctadesc}
                            >
                                {cta.desc}
                            </Typography>
                            <a
                                href={cta.redirect}
                                className={`gtm_click_button_comfama_home_body_centroAyuda ${styles.o_acordeon_tabs__ctalink}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {cta.btnLabel}
                            </a>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Fade>
    );
};

export default AcordeonTabs;
