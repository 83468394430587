import React from 'react';
import { ReferenciaContenido } from '../../../interfaces/ReferenciaContenido';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './descripcionArboles.scss';

const DescripcionArboles = (props: ReferenciaContenido) => {
    return (
        <div className="m-desc-arb">
            {props.listaImagenes[0] ? (
                <img
                    className="m-desc-arb__separator m-desc-arb__separator--left"
                    src={props.listaImagenes[0].file.url}
                    aria-label=""
                />
            ) : null}
            {props.listaImagenes[1] ? (
                <img
                    className="m-desc-arb__separator m-desc-arb__separator--right"
                    src={props.listaImagenes[1].file.url}
                    aria-label=""
                />
            ) : null}
            <img
                className="m-desc-arb__bg"
                src={props.imagenPrincipal.file.url}
                aria-label=""
            />
            <div className="m-desc-arb__text">
                {documentToReactComponents(props.descripcion.json)}
            </div>
        </div>
    );
};

export default DescripcionArboles;
