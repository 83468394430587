import React, { ReactElement, useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import ArrowButton from '../../atoms/arrow-button/arrowButton';
import Bubbles from '../../atoms/bubbles/bubbles';
import transitionElement from '../../../utils/transitionElement';
import useBrowserMode from '../../../shared/hooks/useBrowserMode';
import './bannerArboles.scss';
import useScreenSize from '../../../hooks/useScreenSize';

export interface IBannerArbProps {
    title: string;
    cards: [
        {
            bgImg: string;
            text: any;
            imgTitle: string;
            imgList: [];
            descTitle: string;
            redirection: string;
            btnTitle: string;
            bgColor: string;
        },
    ];
}

type BackgroundProps = {
    cardPosition: number;
    card: any;
    title?: string;
    children?: ReactElement | any;
};

type SliderProps = {
    cards: any;
    selectedCard: number;
    changeCard: (value: number) => void;
};

const BackgroundComponent = (props: BackgroundProps) => {
    const { card, cardPosition, title, children } = props;
    const screenSize = useScreenSize();
    const { isBrowser, document, window } = useBrowserMode();
    const [scrollImg1, setScrollImg1] = useState<any>();
    const [scrollImg2, setScrollImg2] = useState<any>();
    const [scrollImg3, setScrollImg3] = useState<any>();

    useEffect(() => {
        setTimeout(() => {
            setScrollImg1(document.getElementById('parallax-img1-arboles'));
            setScrollImg2(document.getElementById('parallax-img2-arboles'));
            setScrollImg3(document.getElementById('parallax-img3-arboles'));
        }, 1000);
    }, []);
    
    if (isBrowser) {
        window.addEventListener('scroll', () => {
            let scrollValue = window.scrollY;
            if (scrollImg1)
                scrollImg1.style.marginLeft = scrollValue * -0.9 + 'px';
            if (scrollImg2)
                scrollImg2.style.marginLeft = scrollValue * -1.3 + 'px';
            if (scrollImg2)
                scrollImg2.style.marginBottom = scrollValue * -1.3 + 'px';
            if (scrollImg3)
                scrollImg3.style.marginRight = scrollValue * -0.9 + 'px';
        });

        transitionElement();
    }

    return (
        <div className={`o-banner-arb__bg arb-container-${cardPosition}`}>
            <img className="o-banner-arb__bg__cover" src={card.bgImg} alt="" />
            <div
                className="o-banner-arb__bg__bg"
                style={{ backgroundColor: card.bgColor }}
            />
            <img
                id="parallax-img3-arboles"
                className="o-banner-arb__bg__top-right slow-appear"
                src={card.imgList[0].file.url}
                alt=""
            />
            <img
                id="parallax-img1-arboles"
                className="o-banner-arb__bg__top-left slow-appear"
                src={card.imgList[1].file.url}
                alt=""
            />
            <img
                id="parallax-img2-arboles"
                className="o-banner-arb__bg__bottom-left slow-appear"
                src={card.imgList[2].file.url}
                alt=""
            />
            {cardPosition === 0 && (
                <div className="o-banner-arb__bg__default">
                    <img
                        className="o-banner-arb__bg__default__title"
                        src={card.imgList[card.imgList.length - 1].file.url}
                        alt={title ? title : ''}
                    />
                </div>
            )}
            <div
                className={`o-banner-arb__bg__info  slow-appear ${
                    cardPosition === 0 ? 'default-card-banner-arb' : ''
                }`}
            >
                {cardPosition !== 0 && (
                    <span className="o-banner-arb__bg__info__num">
                        0{cardPosition}
                    </span>
                )}
                {cardPosition === 0 && screenSize < 968 ? null : (
                    <>
                        <h2 className="o-banner-arb__bg__info__title">
                            {card.cardTitle}
                        </h2>
                        <p className="o-banner-arb__bg__info__text">
                            {card.text}
                        </p>
                    </>
                )}
                {card.redirection && (
                    <a
                        className="o-banner-arb__bg__info__btn"
                        href={card.redirection}
                        target="_self"
                    >
                        <span>{card.btnTitle}</span>
                        <i className="icon-chevron-right1" />
                    </a>
                )}
            </div>
            {children}
        </div>
    );
};

const SliderComponent = (props: SliderProps) => {
    const { cards, selectedCard, changeCard } = props;
    const sliderRef = useRef(null);

    const handleCardClick = (index) => {
        changeCard(index);
        sliderRef.current.slickGoTo(index);
    };

    const settings = {
        dots: false,
        infinite: false,
        arrows: cards.length > 3 ? true : false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: (
            <ArrowButton extraFunction={() => changeCard(selectedCard + 1)} />
        ),
        prevArrow: (
            <ArrowButton extraFunction={() => changeCard(selectedCard - 1)} />
        ),
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <Slider {...settings} className="o-banner-arb__slider" ref={sliderRef}>
            {cards.map((card: any, key: number) => (
                <button
                    key={`${key}-${card.descTilte}`}
                    onClick={() => handleCardClick(key)}
                    className="o-banner-arb__slider__card"
                >
                    <img
                        className="o-banner-arb__slider__card__bg"
                        src={card.bgImg}
                    />
                    <span className="o-banner-arb__slider__card__title">
                        {card.cardTitle}
                    </span>
                    <p className="o-banner-arb__slider__card__text">
                        {card.descTitle}
                    </p>
                    {key > 0 && (
                        <span className="o-banner-arb__slider__card__num">
                            0{key}
                        </span>
                    )}
                </button>
            ))}
        </Slider>
    );
};

const BannerArboles = (props: IBannerArbProps) => {
    const { title, cards } = props;
    const [selectedCard, setSelectedCard] = useState<number>(0);
    const [showBubbles, setShowBubbles] = useState<boolean>(true);
    const [showSecondBubbles, setShowSecondBubbles] = useState<boolean>(false);
    const { isBrowser, window } = useBrowserMode();

    useEffect(() => {
        setTimeout(() => {
            setShowSecondBubbles(true);
        }, 3000);
        isBrowser && window.screen.width < 960
            ? setShowBubbles(false)
            : setShowBubbles(true);
    }, []);

    if (isBrowser) {
        window.addEventListener('resize', function () {
            isBrowser && window.screen.width < 960
                ? setShowBubbles(false)
                : setShowBubbles(true);
        });
    }

    return (
        <div className="o-banner-arb">
            <h1 className="o-banner-arb__h1">{title}</h1>
            <BackgroundComponent
                card={cards[selectedCard]}
                cardPosition={selectedCard}
                title={title}
            >
                {showBubbles ? (
                    <>
                        <Bubbles width="90vw" zIndex={1} />
                        {showSecondBubbles && (
                            <Bubbles width="50vw" zIndex={1} />
                        )}
                    </>
                ) : null}
            </BackgroundComponent>
            <SliderComponent
                cards={cards}
                selectedCard={selectedCard}
                changeCard={setSelectedCard}
            />
        </div>
    );
};

export default BannerArboles;
