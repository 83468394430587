import React, { useEffect } from 'react';
import Modal from '../../atoms/modal/modal';
import './treesMenu.scss';
import useBrowserMode from '../../../shared/hooks/useBrowserMode';

type TreesMenuProps = {
    opener: boolean;
    onClose: any;
    list: any;
    bgImg: string;
    titleImg: string;
    closeIcon: string;
};

const TreesMenu = (props: TreesMenuProps) => {
    const { opener, onClose, list, bgImg, titleImg, closeIcon } = props;
    const { document } = useBrowserMode();

    useEffect(() => {
        document.body.classList.add('hide-scrollbar');
    }, []);

    useEffect(() => {
        if (opener) {
            document.body.classList.add('freeze-page');
        } else {
            document.body.classList.remove('freeze-page');
        }
    }, [opener]);

    return (
        <Modal open={opener} className="o-trees-menu slow-appear">
            <>
                {opener ? (
                    <button
                        className="o-trees-menu__close"
                        onClick={() => onClose(false)}
                    >
                        <img alt="Cerrar" src={closeIcon} />
                    </button>
                ) : (
                    <></>
                )}
                <img className="o-trees-menu__bg" src={bgImg} alt="" />
                <div className="o-trees-menu__container">
                    <img
                        src={titleImg}
                        className="o-trees-menu__container__title"
                        alt=""
                    />
                    <div className="o-trees-menu__container__cards">
                        {list.list.map((tab, key) => (
                            <button
                                key={key}
                                className="o-trees-menu__container__cards__card"
                                onClick={() => tab.onClick()}
                            >
                                <img src={tab.img} alt="" />
                                <div className="o-trees-menu__container__cards__card__info">
                                    <h3 className="o-trees-menu__container__cards__card__info__title">
                                        {tab.title}
                                    </h3>
                                    <h4 className="o-trees-menu__container__cards__card__info__subtitle">
                                        {tab.text}
                                    </h4>
                                </div>
                            </button>
                        ))}
                    </div>
                </div>
            </>
        </Modal>
    );
};

export default TreesMenu;
