import React, { useEffect, useRef, useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Bubbles from '../../atoms/bubbles/bubbles';
import Modal from '../../atoms/modal/modal';
import TabSelector from '../../molecules/tab-selector/tabSelector';
import YoutubePlayer from '../../molecules/youtube-player/youtubePlayer';
import { ReferenciaContenido } from '../../../interfaces/ReferenciaContenido';
import useBrowserMode from '../../../shared/hooks/useBrowserMode';
import transitionElement from '../../../utils/transitionElement';
import TreesMenu from '../trees-menu/treesMenu';
import Prefooter from '../../molecules/prefooter/prefooter';
import './treesCards.scss';

const TreesCards = (props: ReferenciaContenido) => {
    const { document } = useBrowserMode();
    const [switcher, setSwitcher] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [showMenuBtn, setShowMenuBtn] = useState(true);
    const [openVideo, setOpenVideo] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState('');
    const refComponent = useRef(null);

    useEffect(() => {
        if (props.descripcion) {
            setOpenInfo(true);
        }
    }, []);

    useEffect(() => {
        document.body.classList.add('hide-scrollbar');
    }, []);

    useEffect(() => {
        if (switcher || openInfo || openVideo) {
            document.body.classList.add('freeze-page');
            setShowMenuBtn(false);
        } else {
            setShowMenuBtn(true);
            document.body.classList.remove('freeze-page');
        }
    }, [switcher, openInfo, openVideo]);

    useEffect(() => {
        if (!openVideo) {
            setShowMenuBtn(true);
        }
    }, [openVideo]);

    const handleVideo = (youtubeId) => {
        setShowMenuBtn(false);
        setSelectedVideo(youtubeId);
        setOpenVideo(true);
    };

    transitionElement();

    const goToTree = (index) => {
        setSwitcher(false);
        const el: HTMLElement = refComponent.current || new HTMLElement();
        setTimeout(() => {
            const tree = el.querySelector(`.o-trees-cards__card--${index}`);
            tree?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }, 300);
    };

    const menuList = () => {
        return {
            list: props.referenciaPlantillaContenido.map((tab, index) => ({
                title: tab.tituloBanner,
                text: tab.tituloDescripcion,
                img: tab.imagenPrincipal[0].file.url,
                onClick: () => goToTree(index),
            })),
        };
    };

    return (
        <div className="o-trees-cards" ref={refComponent}>
            <Modal
                open={openVideo}
                className="o-trees-cards__video slow-appear"
                onClose={() => setOpenInfo(false)}
            >
                <>
                    <button
                        className="o-trees-cards__video__close"
                        onClick={() => setOpenVideo(false)}
                    >
                        <img
                            src={props.listaImagenes[4].file.url}
                            alt="Cerrar video"
                        />
                    </button>
                    <div className="o-trees-cards__video__video">
                        <YoutubePlayer youtubeId={selectedVideo} />
                    </div>
                </>
            </Modal>
            {props.descripcion && (
                <Modal
                    open={openInfo}
                    className="o-trees-cards__info slow-appear"
                    onClose={() => setOpenInfo(false)}
                >
                    <>
                        <button
                            className="o-trees-cards__info__close"
                            onClick={() => setOpenInfo(false)}
                        >
                            <img
                                src={props.listaImagenes[4].file.url}
                                alt="Cerrar ventana de información"
                            />
                        </button>
                        <div className="o-trees-cards__info__text">
                            <img
                                className="o-trees-cards__info__text__title"
                                alt={props.listaImagenes[3].title}
                                src={props.listaImagenes[3].file.url}
                            />
                            <div className="o-trees-cards__info__text__desc">
                                {documentToReactComponents(
                                    props.descripcion.json,
                                )}
                            </div>
                        </div>
                    </>
                </Modal>
            )}
            <img
                className="o-trees-cards__border"
                alt=""
                src={props.listaImagenes[0].file.url}
            />
            {!switcher && showMenuBtn ? (
                <div className="o-trees-cards__menu-btn">
                    <button
                        className="o-trees-cards__menu-btn__opts"
                        onClick={() => setSwitcher(true)}
                    >
                        <img
                            loading="lazy"
                            alt={props.listaImagenes[2].title}
                            src={props.listaImagenes[2].file.url}
                        />
                    </button>
                    {props.descripcion && (
                        <button
                            className="o-trees-cards__menu-btn__info"
                            onClick={() => setOpenInfo(true)}
                        >
                            <img
                                loading="lazy"
                                alt={props.listaImagenes[5].title}
                                src={props.listaImagenes[5].file.url}
                            />
                        </button>
                    )}
                </div>
            ) : (
                <></>
            )}
            <TreesMenu
                opener={switcher}
                onClose={() => setSwitcher(false)}
                list={menuList()}
                bgImg={props.listaImagenes[1].file.url}
                titleImg={props.listaImagenes[3].file.url}
                closeIcon={props.listaImagenes[4].file.url}
            />
            <div className="o-trees-cards__cards">
                {props.referenciaPlantillaContenido.map((card, index) => (
                    <div
                        className={`o-trees-cards__card o-trees-cards__card--${index}`}
                        key={index}
                    >
                        <div className="o-trees-cards__card__info">
                            <img
                                loading="lazy"
                                className="o-trees-cards__card__info__bg"
                                alt=""
                                src={props.listaImagenes[1].file.url}
                            />
                            <div className="o-trees-cards__card__info__container">
                                <h3 className="o-trees-cards__card__info__container__title slow-appear">
                                    {card.tituloBanner}
                                </h3>
                                <h4 className="o-trees-cards__card__info__container__subtitle slow-appear">
                                    {card.tituloDescripcion}
                                </h4>
                                <p className="o-trees-cards__card__info__container__desc slow-appear">
                                    {documentToReactComponents(
                                        card.descripcion.json,
                                    )}
                                </p>
                                {card.redireccionTabs ? (
                                    <TabSelector
                                        mainItem={card.tituloBanner}
                                        tabs={card.redireccionTabs}
                                    />
                                ) : (
                                    <></>
                                )}
                                {card.redireccionBoton ? (
                                    <div className="o-trees-cards__card__info__container__video">
                                        <button
                                            onClick={() =>
                                                handleVideo(
                                                    card.redireccionBoton,
                                                )
                                            }
                                        >
                                            {card.tituloBoton}
                                        </button>
                                        <i className="icon-chevron-right1"></i>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                        {card.imagenPrincipal && (
                            <div className="o-trees-cards__card__imgs">
                                {card.imagenPrincipal.map((img, index) => (
                                    <img
                                        loading="lazy"
                                        src={img.file.url}
                                        alt={card.tituloBanner}
                                        key={index}
                                        className={`o-trees-cards__card__imgs__img-${index}`}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <Prefooter
                bg={props.listaImagenes[6].file.url}
                text={props.evento}
                url={props.redireccionBoton}
                btnLabel={props.tituloBoton}
            />
            <Bubbles height={'100%'} />
            <Bubbles height={'75%'} />
            <Bubbles height={'50%'} />
            <div className="o-trees-cards__bg" />
            {props.listaImagenes[7] ? (
                <img
                    id="tree--leaf"
                    loading="lazy"
                    className="o-trees-cards__leaf"
                    alt=""
                    src={props.listaImagenes[7].file.url}
                />
            ) : (
                <></>
            )}
        </div>
    );
};

export default TreesCards;
